<template lang="html">
  <div class="">
    <div class="">
        <vx-card class="">
          <div class="row w-full">
            
            <div class="col-md-8">
              <span class="span-placeholder">Escriba su nota</span>
              <vs-textarea  v-model="nota" />
            </div>
            <div class="col-md-8">
                <span class="span-placeholder">Banco</span>
                <select name="cars" id="formasEnganche" v-model="banco" class="vs-input">
                  <option :value='tipo.Id' v-for="tipo in bancos"  :key="">{{tipo.Nombre}}</option>
                </select>
            </div>
          </div>
          <br>
          <button class="btn btn-second" @click="registraNota()">Agregar nota</button>
                  <div title="" class="vs-input" v-if="notas">
          <div class="px-4 py-4 list-item-component" v-for="nota in notas"  :key="nota.Id">
            <div class="vx-row">
                <div class="vx-col w-full sm:w-5/6 flex sm:items-center sm:flex-row flex-col">
                    <div class="flex items-center">
                        <h6 class="todo-title">{{ nota.Autor }}</h6>
                    </div>
                    <div class="todo-tags sm:ml-2 sm:my-0 my-2 flex">
                      <vs-chip>
                        <span>{{ nota.FechaPublicacion }}</span>
                      </vs-chip>
                      <vs-chip v-if="nota.Banco">
                        <span>{{ nota.Banco }}</span>
                      </vs-chip>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full">
                  <p class="mt-2">{{ nota.Nota }}</p>
                </div>
            </div>
        </div>
        </div>
        </vx-card>

     
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    'v-select': vSelect,
  },
  data:()=>({
    nota: '',
    banco:'',
    bancos:[],
    notas:[],
    id:'',
    key:'',
  }),
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    this.getListaBancos()
    this.dameCasoNotas()
  },
  methods:{
    registraNota(){
      let self=this
      var objRequestRegistraCasoNota = {
        strApiKey:this.key,
        strMetodo:"RegistraCasoNota",
        objCaso:{
          Id:this.id,
          Nota:this.nota,
          Banco:this.banco
        }
      }
      this.$axios.post('/',objRequestRegistraCasoNota,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Guardardo exitosamente`,
      	      position:'top-right'
            })
            this.limpiaForm()
            this.dameCasoNotas()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraCasoNota',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
    limpiaForm(){
      this.nota=''
      this.banco=''
    },
    dameCasoNotas(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCasoNotas',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.notas=response.data.objContenido.Notas
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCasoNotas',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getListaBancos(){
      let self=this
      var objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: 'ListaFinancieras',
      }
      this.$axios.post('/',objRequestListaFinancieras,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.bancos=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaTipoAdeudos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>
<style scoped>
  label{
    margin-top: -100%;
    margin-left: 50%;
  }
  .v-select{
      width: 100%;
  }
</style>
